export const fulldiveConfig = (() => {
  const scripts = document.getElementsByTagName("script")
  const initialScript = Array.from(scripts).find(item => item.getAttribute("id") === "fd_chat")
  let res = {}
  if (initialScript) {
    res = {
      chatId: initialScript.getAttribute("chat-id"),
      containerId: initialScript.getAttribute("container-id"),
      token: initialScript.getAttribute("token")
    }
  }
  return res
})();
